var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("添加用户 ")])]}},{key:"default",fn:function(){return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("添加用户 ")]),_c('v-card-text',[_c('form',[_c('validation-provider',{attrs:{"name":"用户名","rules":"required|min:6|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"username",attrs:{"outlined":"","dense":"","label":"用户名","error-messages":errors+_vm.result,"required":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"新密码","type":"password","rules":{
              passwordRegex:'^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$',
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"outlined":"","dense":"","label":"密码","type":_vm.show ? 'text' : 'password',"error-messages":errors,"required":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"姓名","rules":"required||min:2|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"姓名","error-messages":errors,"required":""},model:{value:(_vm.user.nickName),callback:function ($$v) {_vm.$set(_vm.user, "nickName", $$v)},expression:"user.nickName"}})]}}],null,true)})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.createUser()}}},[_vm._v(" 新增 ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("关闭 ")])],1)],1)]}}])})]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }