var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":_vm.getUserRole},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-cog-outline")])],1)]}},{key:"default",fn:function(){return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("角色设置 ")]),_c('v-card-text',[_c('form',[_c('validation-provider',{attrs:{"name":"用户名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.roles,"label":"系统角色","error-messages":errors},model:{value:(_vm.userRole.role),callback:function ($$v) {_vm.$set(_vm.userRole, "role", $$v)},expression:"userRole.role"}})]}}],null,true)})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.setUserRole()}}},[_vm._v(" 保存 ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("关闭 ")])],1)],1)]}}])})]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }