<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
      >添加用户
      </v-btn>
    </template>
    <template #default>

      <validation-observer
          ref="observer"
          v-slot="{invalid}">

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >添加用户
          </v-toolbar>
          <v-card-text>
            <form>
              <validation-provider
                  v-slot="{ errors }"
                  name="用户名"
                  rules="required|min:6|max:10">
                <v-text-field
                    v-model="user.username"
                    outlined
                    dense
                    label="用户名"
                    :error-messages="errors+result"
                    ref="username"
                    required
                ></v-text-field>
              </validation-provider>

              <validation-provider
                  v-slot="{ errors }"
                  name="新密码"
                  type="password"
                  :rules="{
                passwordRegex:'^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$',
              required: true,
            }">
                <v-text-field
                    v-model="user.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    outlined
                    dense
                    label="密码"
                    :type="show ? 'text' : 'password'"
                    :error-messages="errors"
                    required
                    @click:append="show = !show"
                ></v-text-field>
              </validation-provider>

              <validation-provider
                  v-slot="{ errors }"
                  name="姓名"
                  rules="required||min:2|max:10">
                <v-text-field
                    v-model="user.nickName"
                    outlined
                    dense
                    label="姓名"
                    :error-messages="errors"
                    required
                ></v-text-field>
              </validation-provider>
            </form>

          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                @click="createUser()"
                :disabled="invalid"
            >
              新增
            </v-btn>
            <v-btn
                text
                @click="dialog=false"
            >关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>

    </template>
  </v-dialog>
</template>

<script>
import cloudbase from "@/plugins/cloudbase";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, regex, min,max} from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: '请输入{_field_}',
})

extend('passwordRegex', {
  ...regex,
  message: '请确保8-16位密码,必须包含数字及大小写字母',
})

extend('max', {
  ...max,
  message: '{_field_} 长度不可大于 {length}位',
})
extend('min', {
  ...min,
  message: '{_field_} 长度不可小于 {length}位',
})

export default {
  name: "buttonCreateUser",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      dialog: false,
      show:false,
      result:'',
      user: {
        username: '',
        password: '',
        nickName:'',
        gender:'UNKNOWN'
      }
    }
  },
  created() {
    this.user = {
      username: '',
      password: '',
      nickName:'',
      gender:'UNKNOWN'
    }
  },
  methods: {
    createUser() {
      cloudbase.callFunction({
        name: 'create_user',
        data: this.user
      }).then(s => {
        if (s.result.User){
          this.$emit('created')
          this.dialog = false
        }else {
          throw s.result.error
        }
      }).catch(s=>{
        if(s.message.indexOf('exist')>0){
          this.result = '用户名已存在'
        }
      })
    }
  }

}
</script>

<style scoped>

</style>