<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
      @click:outside="getUserRole"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          icon
      >
        <v-icon>mdi-account-cog-outline</v-icon>
      </v-btn>
    </template>
    <template #default>

      <validation-observer
          ref="observer"
          v-slot="{invalid}">

        <v-card>
          <v-toolbar
              color="primary"
              dark
          >角色设置
          </v-toolbar>
          <v-card-text>
            <form>
              <validation-provider
                  v-slot="{ errors }"
                  name="用户名"
                  rules="required">
                <v-select
                    :items="roles"
                    label="系统角色"
                    v-model="userRole.role"
                    :error-messages="errors"
                ></v-select>
              </validation-provider>
            </form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                @click="setUserRole()"
                :disabled="invalid"
            >
              保存
            </v-btn>
            <v-btn
                text
                @click="dialog=false"
            >关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>

    </template>
  </v-dialog>
</template>

<script>
import cloudbase from "@/plugins/cloudbase";
const roleQuery = cloudbase.database().collection('role').get()


const db = cloudbase.database()
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, regex, min, max} from 'vee-validate/dist/rules'
import {roles} from "@/utils/roles";

extend('required', {
  ...required,
  message: '请输入{_field_}',
})

extend('passwordRegex', {
  ...regex,
  message: '请确保8-16位密码,必须包含数字及大小写字母',
})

extend('max', {
  ...max,
  message: '{_field_} 长度不可大于 {length}位',
})
extend('min', {
  ...min,
  message: '{_field_} 长度不可小于 {length}位',
})

export default {
  name: "buttonCreateUser",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dialog: false,
      result: '',
      userRole: {
        role: ''
      },
      roles: roles
    }
  },
  created() {
    roleQuery.then(({data}) => {
      this.roles = data.map(s=>{
        return {
          text:s.name,
          value:s._id
        }
      })
    })
  },
  methods: {
    getUserRole() {
      console.log(this.user.UUId)
      db.collection('user_role')
          .where({
            user_uuid: this.user.UUId
          })
          .get()
          .then((s) => {
            const {data} = s
            if (data.length > 0) {
              this.userRole = data[0]
            } else {
              this.userRole = {
                user_uuid: this.user.UUId,
                role: ''
              }
            }
          })
    },
    setUserRole() {
      if (this.userRole._id) {
        db.collection('user_role')
            .doc(this.userRole._id)
            .update({
              role: this.userRole.role
            })
      } else {
        db.collection('user_role')
            .add(this.userRole)
      }
      this.dialog = false


    }
  },
  watch: {
    dialog(v) {
      if (v) {
        this.getUserRole()
      }
    },
    userRole(v) {
      console.log(v)
    }
  }
}
</script>

<style scoped>

</style>