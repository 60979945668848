<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
  >
    <template #activator="{ on, attrs }">
      <v-btn
          v-if="user.IsDisabled"
          icon
          color="green"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-account-check-outline</v-icon>
      </v-btn>
      <v-btn
          v-else
          icon
          color="red"
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-account-cancel-outline</v-icon>
      </v-btn>
    </template>
    <template #default>
      <v-card>
        <v-card-title>
          提示
        </v-card-title>
        <v-card-text>
          <v-alert
              dense
              outlined
              type="error"
          >
            确定要<strong>{{ user.IsDisabled ? '启用' : '禁用' }}</strong>用户<strong>{{ user.NickName}}</strong>?
          </v-alert>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              color="primary"
              @click="createUser()"
          >
            确定
          </v-btn>
          <v-btn
              text
              @click="dialog=false"
          >取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>

<script>
import app from "@/plugins/cloudbase";

export default {
  name: "buttonCreateUser",
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    createUser() {

      let state = {
        uuid: this.user.UUId,
        status: "DISABLE"
      }
      if (this.user.IsDisabled) {
        state.status = "ENABLE"
      }
      console.log(this.user, state)
      app.callFunction({
        name: 'set-user-state',
        data: state
      }).then(() => {
        this.dialog = false
        this.$emit('changed')
      })
    }
  }

}
</script>

<style scoped>

</style>