<template>
  <div>
    <div class="ma-2">
      <buttonCreateUser @created="getUsers"></buttonCreateUser>
    </div>
    <v-data-table
        dense
        :headers="headers"
        :items="users"
        item-key="UUID"
        class="elevation-1 ma-2"
        :loading="loading"
        loading-text="加载中... 请稍后"
    >
      <template #item.status="{item}">
        {{item.IsDisabled?'禁用':'有效'}}
      </template>
      <template #item.avatar="{item}">
        <v-avatar v-if="item.AvatarUrl" size="24" class="ml-2">
          <img
              :src="item.AvatarUrl"
          >
        </v-avatar>
      </template>
      <template #item.option="{item}">
        <buttonChangeUserState :user="item" @changed="getUsers"></buttonChangeUserState>
        <buttonUserRole :user="item"></buttonUserRole>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import buttonCreateUser from "@/views/userManage/buttonCreateUser";
import buttonChangeUserState from "@/views/userManage/buttonChangeUserState";
import buttonUserRole from "@/views/userManage/buttonUserRole";
import app from "@/plugins/cloudbase";

export default {
  name: "index",
  components: {buttonCreateUser,buttonChangeUserState,buttonUserRole},
  data() {
    return {
      loading:false,
      headers: [
        {
          text: 'OpenId',
          align: 'start',
          sortable: false,
          value: 'WXOpenId',
        },
        {text: '昵称', value: 'NickName'},
        {text: '昵称', value: 'avatar'},
        {text: '登陆ID', value: 'UserName'},
        {text: '状态', value: 'status'},
        {text: '操作', value: 'option'},
        {text: '创建时间', value: 'CreateTime'},
        {text: '修改时间', value: 'UpdateTime'},
        {
          text: 'UUId',
          align: 'start',
          sortable: false,
          value: 'UUId',
          width:200
        },
      ],
      users:[]
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.loading = true
      app.callFunction({
        name: 'get_user_list',
        data:{
          page:1,
          limit:20
        }
      }).then(s=>{
        console.log(s.result)
        this.users = s.result
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>